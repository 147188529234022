@import "assets/sass/netop/_col.scss";
@import "assets/sass/netop/_mixin.scss";
@import "assets/sass/netop/tenants/_entity-tree.scss";

.int-manager-container {
  @extend .row-no-side-margin;
  height: calc(100vh - 180px);
  overflow-y: auto;
  overflow-x: hidden;
}

.tenant-by-int-container {
  height: 100%;
}

.int-form-row {
  height: 30%;
  min-height: 207px;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid black;
}

.int-tenant-list-row {
  height: max(100vh - 475px, 150px);
  margin-top: 1rem;
}

לֹ
.int-list-item {
  cursor: pointer;
  @include hover-selection(#edf6ff);
}

.int-list-item-selected {
  @include active-selection($list-selection);
}

.int-list-item-row {
  @extend .row-no-side-margin;
  padding: 1rem;
  align-items: center;
  cursor: pointer;
}

.int-list-text {
  font-size: 1.1rem;
}

.int-list-titles {
  @extend .row-no-side-margin;
  border-bottom: 1px solid black;
  margin-bottom: 1rem;
  margin-top: 2.2rem;
  font-size: 1rem;
}

.org-int-list-container {
  border-right: 1px solid black;
  @include wide-screens-only {
    flex-grow: 0.3;
  }
  @include macbook-pro {
    flex-grow: 0.4;
  }
}

.int-form-section-container {
  margin: 1rem;
}

.int-form-container {
}

.int-form-buttons-container {
  width: 100%;
}

.int-form-update-button.mat-raised-button {
  min-width: 0 !important;
  width: 164px;
}

.org-int-form-field {
  max-width: 19vw;
}

.int-list-item-text {
  text-transform: capitalize;
}

.int-form {
  max-height: 106px;
  overflow-y: auto;
  overflow-x: hidden;
}

.int-tenant-list-container {
  height: max(100vh - 600px, 105px);
  overflow-y: auto;
  overflow-x: hidden;
}

.int-tenant-list-text {
  font-size: 1rem;
  max-width: 95.5%;

  & .mat-nav-list .mat-list-item {
    font-size: 1rem;
  }
}

.tenants-list-header {
  margin-left: 1.2rem;
}

.int-tenant-buttons-row {
  height: 50px;
}

.int-org-list-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  .mat-list-icon {
    cursor: pointer;
  }

  svg {
    fill: #4db2ff;
    width: 34px;
    height: 34px;
  }

  .mat-ripple {
    overflow: unset;
  }
}

.int-org-list-header {
  margin-top: 0.3rem;
}

.int-org-list-container {
  height: calc(100vh - 375px);
  overflow-y: auto;
  overflow-x: hidden;
}
