@import "./assets/sass/netop/mixin";

$small-grid-header-font: 1.0375rem;
// ad-classes
.ag-row-link {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.ag-high {
  g {
    [fill] {
      fill: red;
      opacity: 1;
    }
  }
}

.ag-medium {
  g {
    [fill] {
      fill: orange;
      opacity: 1;
    }
  }
}

.ag-low {
  g {
    [fill] {
      fill: green;
      opacity: 1;
    }
  }
}

/**
* a column header with hidden text but shown sortable indicator
*/
.ag-grid-hidden-header .ag-header-cell-text {
  opacity: 0;
}

/**
 * Rows with the most severe entities
*/
.ag-grid-severe-row {
  border-left: 3px solid #ff8780 !important;
}

/**
* Non severe rows need a transparent border to make the cell well aligned
*/
.ag-grid-non-severe-row {
  border-left: 3px solid transparent !important;
}

/**
* Add a "separator" between the header and the data
*/
.ag-row-first {
  border-top: 1px solid #BDADAD !important;
}

.ag-cell {
  display: flex;
  align-items: center;
  text-overflow: unset !important;

  app-bar-cell-renderer {
    flex-grow: 1;
  }
}

.ag-header-cell-label {
  font-size: 16px;
}

// hide the scrollbar on grid bar (needed on dashboard)
.entity-list {
  .ag-center-cols-viewport {
    overflow-x: hidden;
  }
}

// health column in dashboard grid
.grid-health-column {
  // text-align: right;
  // min-width: 47px;
  // display: inline-block;
}

.small-font-grids-header {
  & .ag-header-cell-label .ag-header-cell-text {
    font-size: $small-grid-header-font;
  }

  & .ag-header-group-cell-label, .ag-header-cell-label {
    justify-content: center;
  }
}

.ag-theme-material .ag-header-cell, .ag-theme-material .ag-header-group-cell .small-font-grids-header {
  padding: 0 2px 0 0;

}


.small-screen-grid-header {
  @include macbook-pro-and-under {
    .ag-header-cell-label .ag-header-cell-text {
      font-size: $small-grid-header-font;
    }
  }
}

span.ag-header-icon.ag-sort-order {
  display: none !important;
}

.ag-header-cell-label .ag-header-cell-text {
  padding-left: 23px;
  padding-right: 23px;
}

.clickable-cell {
  cursor: pointer;
}

.cell-link-text {
  color: dodgerblue;
  font-weight: 500;
}

.grid-delete-icon {
  position: relative;
  top: 25%;
}

.body-container .ag-unselectable {
  user-select: auto !important;
}
