@import "assets/sass/netop/vars";
// action icon fill
.entities-tree-actions-bar {
  min-width: 100px;
  // the margin that places the toolbar closer to the node data
  // margin-right: 200px;
  margin-left: 50px;
  display: flex;
  padding-top: 4px;
  svg {
    fill: $icons-blue-global;
    width: 18px;
    height: 18px;
  }
  .entities-tree-add-icon {
    svg {
      width: 24px;
      height: 24px;
      margin-left: -4px;
    }
  }
  .entities-tree-view-icon {
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.node-text {
  display: inline-block;
}

.entities-tree-node {
  // the right padding should be enought to create a x-axes
  // overflow for toolbar to fit in in deep tree
  min-width: max-content;
  padding-right: 100px;
  // create the space netween the node and the toolbar
  // the toolbar will have a margin on it's other side
  // to position it closer to the node data
  // justify-content: space-between;
  .mat-icon.mat-list-icon:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
}
.entities-tree {
  padding-left: 27px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: inherit;
  // the overflow of the tree works fine if the overflow of it's
  // container is defined as hidden
  overflow: auto;
  //height: calc(100% - 40px);
  height: calc(100vh - 227px);
  &-search-container {
    position: relative;
    &:focus {
      outline: 0;
    }
  }

  &-search-input {
    height: 48px;
    width: 100%;
    background-color: inherit;

    // background: #ffffff;
    border: 0;
    border-bottom: 1px solid rgba(236, 236, 236, 0.6);
    box-sizing: border-box;
    border-radius: 3px;
    font-size: 16px;
    padding-left: 61px;
    font-style: italic;
    &:focus {
      outline: 0;
      font-style: normal;
    }
  }
  &-node-data {
    display: flex;
    align-items: center;
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
  &-search-icon {
    position: absolute;
    width: 14px;
    height: 14px;
    // right: 14px;
    left: 28px;
    bottom: 21px;
    svg path {
      fill: rgba(152, 152, 152, 0.61);
    }
  }
  /** the toggle of the select placed on the right of the add icon */
  &-toggle-select {
    font-size: 14px;
    margin-left: -8px;
    margin-top: 4px;
    color: $icons-blue-global;
  }
  &-node-icon {
    margin-right: 4px;
    margin-left: 4px;
    &-org {
      width: 18px;
      height: 18px;
      // fill: $organization-color;
    }
    &-tenant {
      width: 20px;
      height: 20px;
      // fill: $tenant-color;
    }
  }
  // contains the add icon and it's toggle icon
  &-add-container {
    display: flex;
    cursor: pointer;
  }
}
.entity-tree-hightlight {
  background-color: #a9caf2;
}
.entity-tree-current-edited-node {
  background-color: #eff6ff;
}
.tree-search-result-node {
  background-color: #ffffe0;
  font-weight: 800;
  color: #2ea1f8;
}
.mat-tree-node.entities-tree-node {
  // height: 30px;
  min-height: 34px;
}
.mat-icon.entities-tree-expand-icon.mat-list-icon {
  width: 16px;
  height: 16px;
  &:hover {
    // disable scale of the expand icon
    transform: scale(1);
  }
}
