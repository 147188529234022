//@import 'themes';

//@import '~@nebular/theme/styles/globals';

// @include nb-install() {
//   @include nb-theme-global();
// };

// Metronic styles
@import "./assets/sass/config";
@import "./assets/sass/style.angular.scss";
@import "./assets/sass/global/layout/aside/skins/dark.scss";
@import "./assets/sass/global/layout/brand/skins/dark.scss";
@import "./assets/sass/global/layout/header/skins/base/light";
@import "./assets/plugins/flaticon/flaticon.css";
@import "./assets/plugins/flaticon2/flaticon.css";
@import "./assets/plugins/line-awesome/css/line-awesome.css";

// Custom styles
@import "./assets/sass/netop/layout";
@import "./assets/sass/netop/mixin";
@import "./assets/sass/netop/globals";
@import "./assets/sass/netop/shared/portal";
@import "./assets/sass/netop/shared/clone-dialog";
@import "assets/sass/netop/shared/legacy_dashboards";
@import "./assets/sass/netop/material";
@import "./assets/sass/netop/shared/accordion";
@import "./assets/sass/netop/style";
@import "./assets/sass/netop/forms";
@import "./assets/sass/netop/highcharts";
@import "./assets/sass/netop/data-grid";
@import "./assets/sass/netop/dialog";
@import "./assets/sass/netop/tenants/tenants_layout";
@import "./assets/sass/netop/tenants/advanced";
@import "./assets/sass/netop/tenants/org-types";
@import "./assets/sass/netop/shared/select-list.scss";
@import "./assets/sass/netop/shared/single-select";
@import "./assets/sass/netop/shared/ngx-select";
@import "assets/sass/netop/shared/legacy_topology";
@import "./assets/sass/netop/shared/topology-with-properties";
@import "./assets/sass/netop/shared/tplg-tooltip";
@import "assets/sass/netop/shared/legacy_properties";
@import "./assets/sass/netop/shared/editable-text";
@import "./assets/sass/netop/shared/widget-big-chart.scss";
@import "./assets/sass/netop/shared/single-widget.scss";
@import "./assets/sass/netop/shared/column-box-container";
@import "./assets/sass/netop/shared/map";
@import "./assets/sass/netop/shared/header-menu";
@import "./assets/sass/netop/shared/side-menue";
@import "./assets/sass/netop/alarms/only-grid";
@import "./assets/sass/netop/settings/setting-tabs";
@import "./assets/sass/netop/settings/setting-layout";
@import "./assets/sass/netop/settings/setting-curd-forms";
@import "./assets/sass/netop/shared/single-venue-fabric";
@import "./assets/sass/netop/tenants/chat_robot_wizard";
@import "./assets/sass/netop/shared/single-entity-actions";
@import "./assets/sass/netop/icons/icon-with-ellipse";
@import "./assets/sass/netop/icons/arrow";
@import "./assets/sass/netop/icons/from-to";
@import "src/assets/sass/netop/col";
@import "./assets/sass/netop/shared/action-configuration-dialog";
@import "./assets/sass/netop/shared/chart-tooltip";
@import "./assets/sass/netop/tenants/entity-tree";
@import "./assets/sass/netop/tenants/org-manage-tenant";
@import "./assets/sass/netop/shared/checkbox-search-list";
@import "./assets/sass/netop/tenants/tenant-setting";
@import "./assets/sass/netop/tenants/define-fabric-rule";
@import "./assets/sass/netop/shared/hint-bubble";
@import "./assets/sass/netop/shared/gauge";
@import "./assets/sass/netop/shared/link";
@import "./assets/sass/netop/shared/under-construction";
@import "./assets/sass/netop/shared/bootstrap-tooltip";
@import "./assets/sass/netop/registration/registration";
@import "./assets/sass/netop/shared/information-tooltip";
@import "./assets/sass/netop/tenants/operation-wizard";
@import "./assets/sass/netop/tenants/port-forwarding";
@import "./assets/sass/netop/tenants/create-fabric";
@import "./assets/sass/netop/tenants/port-forward-diagram";
@import "./assets/sass/netop/shared/diagram";
@import "./assets/sass/netop/shared/netop-modals";
@import "./assets/sass/netop/dynamic-dashboard";
@import "./assets/sass/netop/shared/edit-layout";
@import "./assets/sass/netop/general-classed";
@import "./assets/sass/netop/single-kpi-chart";
@import "./assets/sass/netop/maintenance";
@import "./assets/sass/netop/reports/reports";
@import "./assets/sass/netop/shared/filters-bar";
@import "./assets/sass/netop/shared/from-to-datepicker";
@import "assets/sass/netop/shared/list-view";
@import "./assets/sass/netop/shared/horizontal-timeline";
@import "./assets/sass/netop/shared/single-portlet";
@import "./assets/sass/netop/force-directed-graph/force-directed-node";
@import "./assets/sass/netop/force-directed-graph/force-directed-links";
@import "./assets/sass/netop/shared/info-bar";
@import "./assets/sass/netop/shared/period-time";
@import "./assets/sass/netop/ag-grid-renderers/time-bar-renderer";
@import "./assets/sass/netop/ag-grid-renderers/ag-grid-cell-renderers";
@import "./assets/sass/netop/shared/nav-bar";
@import "./assets/sass/netop/shared/error_message";
@import "./assets/sass/netop/shared/drop-down-arrow";
@import "./assets/sass/netop/top-traffic";
@import "./assets/sass/netop/shared/kpis-bar";
@import "./assets/sass/netop/shared/pressed-button";
@import "./assets/sass/netop/anomalities/anomaly-threshold";
@import "./assets/sass/netop/shared/dynamic-wording";
@import "./assets/sass/netop/accounts/org-integration-manager";
@import "./assets/sass/netop/reports/reports-deviation-dialog";
@import "./assets/sass/netop/multi-kpis-chart";
@import "./assets/sass/netop/shared/spinner";
@import "./assets/sass/netop/insights-anomalies/insights-anomalies";
@import "./assets/sass/netop/shared/kpi_changes";
@import "./assets/sass/netop/shared/graph-type-selectors";
@import "./assets/sass/netop/shared/main-actions-dashboard";
@import "./assets/sass/netop/venue/subnet-display";
@import "./assets/sass/netop/tenants/tracing-screen";
@import "./assets/sass/netop/shared/netop-toggle";
@import "./assets/sass/netop/shared/donut_dialog";
@import "./assets/sass/netop/shared/chart";
@import "./assets/sass/netop/anomalities/analyze-pop-over";
@import "./assets/sass/netop/ag-grid-renderers/ag-grid-override-cell";
@import "assets/sass/netop/reports/legacy-reports-sender";
@import "./assets/sass/netop/shared/button";
@import "./assets/sass/netop/venue/bandwidth";
@import "./assets/sass/netop/header/login";
@import "./assets/sass/netop/venue/open-ports";
@import "./assets/sass/netop/venue/kpis-tree";
@import "./assets/sass/netop/default-page";
@import "./assets/sass/netop/security/security-compliance";
@import "./assets/sass/netop/shared/time-manager";
@import "./assets/sass/netop/venue/venue-kpi-list";
@import "./assets/sass/netop/shared/search-menu-box";
@import "./assets/sass/netop/shared/assignment-dialog";
@import "./assets/sass/netop/drag-drop";
@import "./assets/sass/netop/shared/sla-monitor";
@import "./assets/sass/netop/shared/selection-tree";
@import "./assets/sass/netop/shared/time-period-picker";
@import "./assets/sass/netop/shared/statistics";
@import "./assets/sass/netop/shared/resizable";
@import "./assets/sass/netop/shared/dashboard";
@import "./assets/sass/netop/shared/confirmation-dialog";
@import "./assets/sass/netop/shared/properties";
@import "./assets/sass/netop/shared/topology";
@import "./assets/sass/netop/shared/devices";
@import "./assets/sass/netop/shared/bandwidth";
@import "./assets/sass/netop/shared/venues-management";
@import "./assets/sass/netop/shared/integrations";
@import "./assets/sass/netop/shared/entity-picker-dialog";
@import "./assets/sass/netop/shared/top-sites-widget";
@import "./assets/sass/netop/shared/menu_cell_renderer";
@import "./assets/sass/netop/shared/incidents";
@import "./assets/sass/netop/shared/dynamic-form";
@import "./assets/sass/netop/shared/tabbed-sidebar";
@import "./assets/sass/netop/shared/device-dashboard";
@import "assets/sass/netop/reports/reports-scheduler";
@import "assets/sass/netop/reports/reports-sender";
@import "assets/sass/netop/shared/assets";

// Ag-Grid
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-material.css";
// @import "~ag-grid-community/dist/styles/ag-theme-balham.css";

.la-1-5x {
  font-size: 1.5em;
}

$regularSize: 1em;
$regularPlusSize: 1.5em;
$mediumSize: 2em;
$mediumPlusSize: 2.5em;
$largeSize: 3em;
$largePlusSize: 3.5em;
$extraLargeSize: 4em;
$extraLargePlus: 4.5em;

.svg-regular {
  svg {
    width: $regularSize;
    height: $regularSize;
  }
}

.svg-regular-plus {
  svg {
    width: $regularPlusSize;
    height: $regularPlusSize;
  }
}

.svg-medium {
  svg {
    width: $mediumSize;
    height: $mediumSize;
  }
}

.svg-medium-plus {
  svg {
    width: $mediumPlusSize;
    height: $mediumPlusSize;
  }
}

.svg-large {
  svg {
    width: $largeSize;
    height: $largeSize;
  }
}

.svg-large-plus {
  svg {
    width: $largePlusSize;
    height: $largePlusSize;
  }
}

.svg-extra-large-plus {
  svg {
    width: $extraLargePlus;
    height: $extraLargePlus;
  }
}

.svg-brand-color {
  @include svg-fill-color(kt-state-color(brand));
}

.svg-light-color {
  @include svg-fill-color(kt-state-color(light));
}

.svg-dark-color {
  @include svg-fill-color(kt-state-color(dark));
}

.svg-primary-color {
  @include svg-fill-color(kt-state-color(primary));
}

.svg-success-color {
  @include svg-fill-color(kt-state-color(success));
}

.svg-info-color {
  @include svg-fill-color(kt-state-color(info));
}

.svg-warning-color {
  @include svg-fill-color(kt-state-color(warning));
}

.svg-danger-color {
  @include svg-fill-color(kt-state-color(danger));
}

// Breadcrumbs
.kt-subheader__breadcrumbs {
  display: flex;
  align-items: center;
  list-style-type: none;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;

  .kt-subheader__breadcrumbs-home {
    padding: 0 0.65rem 0 0;
    @include kt-transition();

    > i {
      font-size: 1rem;
      color: #c5cbe7;
    }

    &:hover {
      > i {
        @include kt-transition();
        color: kt-brand-color();
      }
    }

    &.kt-subheader__breadcrumbs-home--active {
      > i {
        color: kt-brand-color();
      }
    }
  }

  .kt-subheader__breadcrumbs-link {
    padding: 0 0.35rem 0 0;
    font-size: 1rem;
    font-weight: 500;
    color: #959cb6;

    &:hover {
      color: kt-brand-color();
    }

    &.kt-subheader__breadcrumbs-link--active {
      color: kt-brand-color();
    }

    @include kt-hover-transition();
  }

  .kt-subheader__breadcrumbs-separator {
    display: flex;
    justify-content: content;
    align-items: center;
    padding: 0 0.35rem 0 0;

    &:after {
      display: block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      content: " ";
      background: #e7e8ef;
    }
  }
}

// Toaster
.success-toaster {
  background-color: kt-state-color(success);
}

// Page-loader
.page-spinner-dialog .mat-dialog-container {
  box-shadow: none;
  background-color: transparent;
  overflow: hidden !important;
}

// ~~~ dialog
.mat-dialog-container {
  padding: 0 !important;
}

.add-fabric-dialog {
  .dialog-header {
    padding: 24px;
    background-color: kt-brand-color();
    color: white;
    margin: 0 0 1.25em 0;
  }

  .mat-dialog-content {
    margin: 0;
    overflow: visible !important;
  }

  .mat-dialog-actions {
    padding: 24px;
  }

  .mat-dialog-title {
    margin: 0;
  }
}

// ~ SnackBar
.mat-snack-bar-alert {
  width: 15000px;
  background: #dc143c;
  color: white;
  font-weight: bold;
}

// ~~~ menu
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__heading .kt-menu__link-text,
.kt-aside-menu .kt-menu__nav > .kt-menu__item > .kt-menu__link .kt-menu__link-text,
.kt-aside-menu .kt-menu__nav > .kt-menu__section .kt-menu__section-text {
  color: #aeafc7;
}

.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__heading,
.kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link {
  background-color: #13131d;
}

.kt-aside-menu
.kt-menu__nav
> .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
> .kt-menu__heading,
.kt-aside-menu
.kt-menu__nav
> .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover
> .kt-menu__link {
  background-color: #13131d;
}

// ~~~ general
.kt-avatar .kt-avatar__upload i {
  font-size: 1.4rem;
}

.kt-cursor-pointer {
  cursor: pointer;
}

html,
body {
  height: 100%;
}

.body-container {
  margin: 0;
  font-family: Rubik, Roboto, "Helvetica Neue", sans-serif;
  overflow-x: hidden;
  overflow-y: hidden;
}

.mat-drawer-container {
  background-color: inherit;
}

.vertical-divider {
  height: 70%;
  border-right: 1px solid $purple-greyish-x-lighter;
}

.dialog-properties-header-container{
  padding-left: 15px;
  padding-right: 15px;

  .dialog-properties-close-icon-container {
    text-align: right;
  }
}

.mat-placeholder-required {
  color: red !important;
}
