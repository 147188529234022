@import "src/assets/sass/netop/col";
@import "assets/sass/netop/_mixin.scss";

.main-actions-container-row {
  height: 100%;
  @extend .row-no-side-margin;
}
.main-actions-container-col {
  padding: 1rem;
}
.main-actions-filters-container {
  height: max(10%, 80px);
  @extend .row-no-side-margin;
  margin-bottom: 1rem;
}
.main-actions-list-container {
  height: calc(100vh - 260px);
  @extend .row-no-side-margin;
}
.main-action-filters-row {
  background-color: #fafafa;
  height: 100%;
}
.main-action-filter-col {
  border-right: 1px solid;
  overflow-y: auto;
  height: 100%;
  width: 85%;
}
.main-action-bar-col {
  @include macbook-pro {
    max-width: 9vw;
  }
  @include wide-screens-only {
    max-width: 9vw;
  }
}
