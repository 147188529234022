.setting-header {
  background: #ffffff;
  height: 48px;
}
.setting-header-text {
  font-size: 22px;
  color: #467fca;
}
/**
* Container for setting/operations
*/
.operations-settings-container {
  @media (max-height: 747px) {
    min-height: 364px;
  }
  padding: 18px; // 20px padding creates a scrollbar
  /** header  40px, Health/Actions tab 48px, already include the padding before and after (2 x 20) 
  and another 6px i can't explain*/
  height: calc(100vh - 94px);
  overflow-y: auto;
  overflow-x: hidden;
  &.tenants-main-container {
    height: max(364px, 100vh - 109px);
    overflow-y: hidden;
  }
}
