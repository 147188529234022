@import "assets/sass/netop/vars";

.operations-settings-container .mat-tab-links {
  .mat-tab-label,
  .mat-tab-link {
    font-size: 18px;
    line-height: 21px;
    opacity: 1;
    color: $blue-global;
  }

  .setting-tabs-icon {
    padding-right: 10px;
    svg {
      fill: $blue-global;
    }
    svg path {
      fill: #467fca;
    }
  }
  .code-fork-icon {
    svg {
      width: 28px;
      height: 21px;
    }
  }
  .maintenance-icon {
    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.brand-icon {
  svg {
    height: 30px;
    width: 30px;
  }
}
.org-types-icon {
  svg {
    height: 30px;
    width: 30px;
  }
  path {
    fill: $blue-global;
  }
}
.service-icon {
  svg {
    height: 20px;
    width: 20px;
  }
}
