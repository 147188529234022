@import "assets/sass/netop/vars";

/** remove the bg color from default material toolbar */
.mat-toolbar {
  background-color: initial;
}

.filter-toggle .mat-button-toggle-group {
  width: 100%;
  justify-content: space-around;
}

.filter-toggle .mat-button-toggle {
  width: 100%;
}

.select-contents-display .mat-form-field-infix {
  display: contents;
}
.select-contents-display .mat-select {
  display: contents;
}
.select-contents-display .mat-select-trigger {
  display: inline;
}
.select-contents-display .mat-select-value {
  display: contents;
  max-width: unset;
}
.select-contents-display .mat-select-arrow-wrapper {
  display: inline-table;
}

.body-container .mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: $accent-color;
}

.body-container .mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: $warn-color;
}

.body-container .mat-tooltip {
  background-color: $accent-color;
  color: white;
  font-size: 14px;
}

.body-container .mat-checkbox .mat-ripple-element {
  background-color: unset;
}

.body-container .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.body-container .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background-color: unset;
}

.body-container .mat-tab-link.mat-tab-disabled {
  opacity: 0.2 !important;
}

.body-container .mat-divider.mat-divider-vertical {
  height: 100%;
  margin-right: 10px;
  border-right-color: $purple-greyish-xx-lighter;
}
