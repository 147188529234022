.spinner-loading-hide {
  visibility: hidden;
}

.spinner-loading .mat-progress-spinner {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  //z-index: 10;
}
