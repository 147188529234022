.filters-bar-row {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.filters-form-field {
  padding-top: 1rem;
  max-width: 100%;
}

.filter-bar-col {
  width: 10%;
}

.filter-multi-selection {
  opacity: 0.75;
  font-size: 0.75em;
}
