.time-range-picker .time-period-picker mat-form-field {
  margin-left: 20px;
}

.time-range-picker-menu.mat-menu-panel {
  min-width: 304px;
  max-width: 304px;
  overflow: hidden;
}

.time-range-picker-menu .mat-form-field-wrapper {
  padding-bottom: 0;
}

.time-range-picker-menu .mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0;
}

.time-range-picker-menu, .time-period-picker {
  & .mat-form-field-appearance-legacy .mat-form-field-underline {
    display: none;
  }
}

.dashboard-time-picker {
  position: relative;
  top: 5px;
}

.date-time-picker {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date-picker-col .mat-form-field-infix {
  display: flex;
  padding-left: 20px;
  width: 135px;
}

.time-picker {
  display: flex;
  justify-content: space-around;
  width: 74%;
}

.time-picker-col {
  margin-right: 5px
}

.time-picker-col mat-form-field {
  width: 60px;
  border: 1px solid gainsboro;
  padding: 0 5px;
}

.back-to-now-text {
  cursor: pointer;
  margin-left: 10px;
  font-size: 13px;
  color: #467fca;
}

.apply-button.mat-button, .apply-button.mat-icon-button, .apply-button.mat-stroked-button, .apply-button.mat-flat-button {
  min-width: 64px;
  line-height: 24px;
  padding: 0 4px;
}

.time-period-input .mat-form-field-appearance-legacy .mat-form-field-infix {
  width: fit-content;
}
