@mixin retina-display {
  @media screen and (min-width: 2880px) {
    @content;
  }
}

@mixin wide-screens-only {
  @media screen and (min-width: 1681px) {
    @content;
  }
}

@mixin macbook-pro {
  @media screen and (min-width: 1200px) and (max-width: 1680px) {
    @content;
  }
}

@mixin macbook-pro-and-under {
  @media screen and (max-width: 1680px) {
    @content;
  }
}

@mixin inch-13-and-under {
  @media screen and (max-width: 1440px) {
    @content;
  }
}

/* (1366x768) WXGA Display */
@mixin WXGA-screen {
  @media screen and (max-width: 1366px) {
    @content;
  }
}

/* (1280x1024) SXGA Display */
@mixin SXGA-screen {
  @media screen and (max-width: 1280px) {
    @content;
  }
}

/* (2048x1536) iPad 3 */
@mixin Ipad3-screen {
  @media only screen and (max-width: 1536px) {
    @content;
  }
}

@mixin trim($numLines: null) {
  @if $numLines != null {
    // display: -webkit-box;
    -webkit-line-clamp: $numLines;
    overflow: hidden;
    text-overflow: ellipsis !important;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
  } @else {
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden;
    display: block;
  }
}

@mixin svg-fill-color($color) {
  svg {
    g [fill] {
      fill: $color;
    }
  }
}

@mixin hover-selection($background-color) {
  background-position: center;
  transition: background-color 0.4s;
  :hover {
    background-color: $background-color;
  }
}

@mixin active-selection($background-color) {
  background-color: #c0e2fe;
  :hover {
    background-color: #c0e2fe;
    transition: background-color 0.8s;
    background-size: 100%;
  }
}

@mixin fade-in-out($color) {
  color: $color;
  animation: fadeOut ease 6s;
  -webkit-animation: fadeOut ease 6s;
  -moz-animation: fadeOut ease 6s;
  -o-animation: fadeOut ease 6s;
  -ms-animation: fadeOut ease 6s;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@mixin rotate-icon {
  -webkit-animation: netop-spin 6s 3;
  animation: netop-spin 6s 3;
  transform-origin: 50% 50% 0;

  @-webkit-keyframes netop-spin {
    0% {
      transform-origin: 50% 50% 0;
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      transform-origin: 50% 50% 0;
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  @keyframes netop-spin {
    0% {
      transform-origin: 50% 50% 0;
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      transform-origin: 50% 50% 0;
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
}

@mixin rotate-arrow($direction) {
  -webkit-animation: $direction 1s 1;
  animation: $direction 1s 1;
  // transform-origin: 50% 50% 0;

  @-webkit-keyframes arrow-spin-down {
    0% {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }
    100% {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }
  @keyframes arrow-spin-up {
    0% {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
    100% {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
    }
  }
}
